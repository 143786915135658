<template>
    <content-view v-if="isPageLoaded" class="w-100">
        <div
            v-if="knowledgeBase.is_editor"
            class="knowledge-base__control"
        >
            <button
                type="button"
                class="knowledge-base__material__btn"
                @click="addMaterial"
            >
                <icon height="12" width="12" icon-color="#989CAE">
                    <path
                        d="M0 9.49953V11.9999H2.50035L9.87802 4.62217L7.37767 2.12183L0 9.49953Z"
                    />
                    <path
                        d="M11.5985 1.7519L10.0416 0.195027C9.78154 -0.065009 9.35813 -0.065009 9.0981 0.195027L7.87793 1.4152L10.3783 3.91554L11.5984 2.69537C11.8585 2.43534 11.8585 2.01193 11.5985 1.7519Z"
                    />
                </icon>
            </button>
        </div>

        <div class="material__content editor__content" id="page-print">
            <new-editor
                class="content__editor"
                :data="knowledgeBase.text"
                :editable="false"
                :top-title="knowledgeBase.title"
                top-placeholder="Название материала"
                bottom-placeholder="Описание материала"
            />
        </div>
    </content-view>
    <loading-spinner v-else />
</template>

<script>
    import ContentView from '@/views/menu/ContentView'
    import session from '@/api/session'
    import { metaTitle } from '@/mixins/meta_title'
    import LoadingSpinner from '@/components/LoadingSpinner'
    import { mapGetters } from 'vuex'
    import NewEditor from '@/components/editor/newEditor'

    export default {
        name: 'KnowledgeBase',
        mixins: [metaTitle],
        data() {
            return {
                baseName: 'База знаний',
                timer: null,
                knowledgeBase: {},
                isPageLoaded: false
            }
        },
        computed: {
            ...mapGetters('default_data', ['knowledgeBaseId', 'isBlocked']),

            setTitle() {
                return this.baseName
            }
        },
        components: {
            NewEditor,
            ContentView,
            LoadingSpinner,
            Icon: () => import('@/components/icon/Icon')
        },
        async created() {
            try {
                const request = session.get(
                    `/api/v1/material/${this.knowledgeBaseId}/`
                )
                const response = await request
                this.knowledgeBase = {
                    ...response.data,
                    text: response.data.text.indexOf('<menu id=') + 1 ? response.data.text : response.data.text + '<menu id=""></menu>'
                }

                this.isPageLoaded = true
            } catch (error) {
                this.$router.push('/404')
            }
        },
        methods: {
            addMaterial() {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    this.$router.push('/knowledge/edit/')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '#sass/v-style';

    .knowledge-base {
        &__control {
            display: flex;
            justify-content: flex-end;
        }
    }
    .knowledge-base__material {
        margin-top: 30px;
        &__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            border: 2px solid #e6ebf3;
            background-color: transparent;
            &:hover {
                cursor: pointer;
                border-color: $gold;
                svg,
                path {
                    fill: black;
                }
            }
            &:nth-child(n + 2) {
                margin-left: 10px;
            }
        }
    }
</style>
